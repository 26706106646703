$header-and-footer-mobile-height: 69 + 186;
.content{
  min-height: calc(100vh - #{$header-and-footer-mobile-height}px);
  padding: 28px 11px;

  @include desktop{
    flex: 1 0 auto;
    padding: 95px 52px;
    min-height: 100vh;
  }

  .h1{
    margin-bottom: 26px;

    @include tablet{
      max-width: 558px;
    }
  }

  p{
    margin-bottom: 24px;

    @include tablet{
      max-width: 558px;

      &:not(:first-of-type){
        max-width: 518px;
      }
    }
  }
}
.content_bg{
  @include tablet{
    background-repeat: no-repeat;
    background-position: 534px center;
  }
  @include desktop{
    background-position: 534px -30px;
    background-size: auto 140%;
  }
}

.content__buttons{
  padding-top: 9px;

  @include tablet{
    display: flex;
  }

  @include desktop{
    padding-top: 0;
    max-width: 558px;
  }

  .button{
    margin-bottom: 22px;

    @include tablet{
      margin-right: 22px;
      margin-bottom: 0;
    }
  }
}
