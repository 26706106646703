.form{
  padding-top: 10px;

  @include tablet{
    max-width: 502px;
    padding-top: 16px;
  }
}

.form__items{
  margin-bottom: 34px;

  @include tablet{
    display: flex;
    flex-wrap: wrap;
    margin-left: -7px;
    margin-right: -7px;
  }
}

.form__item{
  @include tablet{
    flex: 1 0 auto;
    min-width: 90%;
    margin: 0 7px 14px;

    &:last-of-type{
      margin-bottom: 0;
    }
  }

  label{
    @extend .visuallyhidden;
  }

  input{
    @include reset-input();
    display: block;
    width: 100%;
    padding: 10px 14px;
    margin-bottom: 14px;
    border-radius: 5px;
    //background-color: rgba(255,255,255,0.15);
    background-color: #262626;
    line-height: 1;
    font-family: $ExoLight;
    color:#fff;
    transition: outline-color .3s ease-out;
    @include placeholder(rgba(255,255,255,0.85));
    @include tablet{
      padding: 8px 14px 12px;
      margin-bottom: 0;
    }
  }
}

.invalid-input{
  outline: 1px solid red;
}

.form__item_tablet-big{
  flex: 1 0 auto;
  min-width: auto;
}

.form__item_tablet-small{
  flex: 0 1 auto;
  min-width: auto;

  @include tablet{
    max-width: 30.5%;
  }
}

.form__bottom{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include tablet{
    justify-content: flex-start;
  }
}

.form__button{
  margin-right: 10px;

  @include tablet{
    margin-right: 40px;
  }
}

.form__required-mark{
  font-size: 14px;
  line-height: 1;
  color: rgba(255,255,255,0.5);

  @include tablet{
    position: relative;
    top: -3px;
  }
}

.form__info{
  span{
    display: block;

    &:first-child{
      margin-top: -16px;
    }

    &:last-child{
      margin-bottom: 20px;
    }
  }
}
