.footer{
  padding: 46px 30px 52px;

  @include desktop{
    padding: 0 0 52px;
    margin-top: auto;
  }
}

.footer_header{
  display: none;

  @include desktop{
    display: block;
  }
}

.footer_content{
  background-color: #fff;

  @include desktop{
    display: none;
  }
}

.contacts{
  font-size: 14px;
  line-height: 22px;
  font-family: $ExoMedium;
  color: $grey;

  a{
    color: inherit;
    text-decoration: none;

    &:hover{
      text-decoration: underline;
    }
  }
}

.contacts__name{
  color: #000;
}
