.menu{

}

.menu_opened{
  .menu__items{
    max-height: 3000px;
  }

  .menu__toggle-mobile-button{
    fill: $primary-color;
  }
}

.menu__items{
  @include reset-list();
  width: 100%;
  max-height: 0;
  padding: 0 42px;
  overflow: hidden;
  background-color: #fff;
  transition: max-height .3s ease-out;
  position: absolute;
  top: 100%;
  left: 0;

  @include desktop{
    max-height: 1000px;
    padding: 0;
    margin-top: 47px;
    margin-bottom: 30px;
    position: static;
  }
}

.menu__item{
  margin-bottom: 22px;
  color: #000;

  @include desktop{
    margin-bottom: 16px;
  }

  &:first-child{
    margin-top: 43px;

    @include desktop{
      margin-top: 0;
    }
  }

  &:last-child{
    margin-bottom: 72px;

    @include desktop{
      margin-bottom: 0;
    }
  }

  a{
    font-size: 24px;
    line-height: 26px;
    font-family: $ExoMedium;
    color: inherit;
    text-transform: uppercase;
    text-decoration: none;

    @include desktop{
      font-size: 16px;
      line-height: 1;
    }

    &:hover{
      color: $primary-color;
    }
  }
}

.menu__item_active{
  color: $primary-color;
}

.menu__toggle-mobile-button{
  @include reset-button();
  width: 20px;
  height: 17px;
  margin-top: 10px;
  transition: fill .3s ease-out;

  @include desktop{
    display: none;
  }

  svg{
    width: 100%;
    height: 100%;
  }
}
