html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html,body{
  overflow-x: hidden;
  background-color: #000;
}

img,svg{
  display: block;
  width: 100%;
  height: auto;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; /* 1 */
}

.wrapper{
  min-height: 100vh;

  @include desktop{
    display: flex;
  }
}
