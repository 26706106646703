// =============================================
// Colors
$primary-color: #dd6a00;
$secondary-color: #fff;
$black-color: #000;
$grey: #adadad;

// =============================================
// Fonts
$fontPath: '../fonts/';
