.button{
  @include reset-button();
  padding: 8px 15px 12px;
  border: 1px solid $primary-color;
  border-radius: 5px;
  font-size: 18px;
  line-height: 18px;
  color: $primary-color;
  transition: background-color .3s ease-out, color .3s ease-out;
  text-decoration: none;

  &:hover{
    background-color: $primary-color;
    color: #fff;
  }
}
