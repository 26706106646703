.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 69px;
  padding-left: 11px;
  padding-right: 14px;
  position: relative;
  background-color: #fff;

  @include tablet{
    padding-left: 30px;
    padding-right: 22px;
  }

  @include desktop{
    flex: 0 0 213px;
    height: auto;
    padding-right: 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding-top: 32px;
  }
}
