$container-sm-width: 320px;
$container-md-width: 768px;
$container-lg-width: 1170px;

$breakpoints: (
  sm: 0,
  md: 767px,
  lg: 1023px
) !default;

.container {
  min-width: $container-sm-width;
  padding: 0 11px;
  margin: 0 auto;

  @include tablet {
    //width: $container-md-width;
    padding: 0 24px;
  }

  @include desktop {
    max-width: $container-lg-width;
    padding: 0 15px;
  }
}

.row {
  display: flex;
  flex-direction: column;

  @include tablet{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
