/* exo-2-300 - latin */
@font-face {
  font-family: 'Exo 2 Light';
  font-style: normal;
  font-weight: 300;
  src: local('Exo 2 Light'), local('Exo2-Light'),
  url('#{$fontPath}exo-2-v4-latin-300.woff2') format('woff2'),
  url('#{$fontPath}exo-2-v4-latin-300.woff') format('woff');
  font-display: swap;
}
/* exo-2-500 - latin */
@font-face {
  font-family: 'Exo 2 Medium';
  font-style: normal;
  font-weight: 500;
  src: local('Exo 2 Medium'), local('Exo2-Medium'),
  url('#{$fontPath}exo-2-v4-latin-500.woff2') format('woff2'),
  url('#{$fontPath}exo-2-v4-latin-500.woff') format('woff');
  font-display: swap;
}

// ====================================
// Text
$ExoLight: 'Exo 2 Light', Arial, Helvetica, sans-serif;
$ExoMedium: 'Exo 2 Medium', Arial, Helvetica, sans-serif;
$font-size-base: 15px;
$line-height-base: 26px;

html {
  font-size: 16px;
  line-height: 24px;
  font-family: $ExoLight;
  color: #fff;

  @include tablet {
    //font-size: $font-size-base * 0.8;
  }

  @include desktop {
    //font-size: $font-size-base * 1;
  }
}

p {
  margin: 0;
}

// ====================================
// Links
a {
  color: #fff;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: #fff;
  }

  &:active,
  &.active {
    font-weight: normal;
    color: #fff;
  }

  &:focus {
    outline: 1px dotted #fff;
  }
}

// ====================================
// Heading
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
  color: #fff;

  em{
    font-style: normal;
    color: $primary-color;
  }
}

h1,
.h1 {
  font-size: 36px;
  line-height: 38px;

  @include tablet {}
}
h2,
.h2 {
  margin-bottom: 22px;
  font-size: 36px;
  line-height: 40px;

  @include tablet {}
}

// ====================================
// Font-sizes

.fz-13 {
  font-size: 13px !important;
}

.fz-14 {
  font-size: 14px !important;
}

.fz-15 {
  font-size: 15px !important;
}

.fz-16 {
  font-size: 16px !important;
}

.fz-17 {
  font-size: 17px !important;
}

.fz-18 {
  font-size: 18px !important;
}

.fz-20 {
  font-size: 20px !important;
}

.fz-24 {
  font-size: 24px !important;
}

.fz-64 {
  font-size: 64px !important;
}

// ====================================
// Font-aligns
.ta-c {
  text-align: center;
}
.ta-l {
  text-align: left;
}
.ta-r {
  text-align: right;
}

// ====================================
// Colors
.primary-color {
  color: $primary-color;
}

.primary-color {
  color: $secondary-color;
}
